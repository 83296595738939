<template>
  <div class="column justify-center items-center">
    <q-icon name="sym_r_warning" class="q-mr-xs" size="24px" />
    <p>No data available.</p>
  </div>
</template>

<script>
export default {
  name: "NoDataAvailable",
  props: {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
