import LZString from "lz-string";

export default {
  data() {
    return {};
  },
  methods: {
    loadStateFromUrl(componentName, data, customKey = "") {
      let urlParams = new URLSearchParams(window.location.search);
      let urlState = urlParams.get(componentName + customKey + "_state");

      if (urlState) {
        urlState = JSON.parse(
          LZString.decompressFromEncodedURIComponent(urlState)
        );
      }

      return urlState;
    },
    saveStateToUrl(componentName, data, customKey = "") {
      let urlParams = new URLSearchParams(window.location.search);

      urlParams.set(
        componentName + customKey + "_state",
        LZString.compressToEncodedURIComponent(JSON.stringify(data))
      );

      window.history.replaceState(
        history.state,
        "",
        "?" + urlParams.toString()
      );
    }
  }
};
