<template>
  <div>
    <!--<PageTitlebar title="Advertiser Overview" />-->

    <div class="row">
      <div class="col-12">
        <AdvertiserReportingOverviewMonitor class="q-mb-lg" />
      </div>
    </div>

    <WidgetGrid group-key="AdvertiserOverview" :widgets="widgets" />
  </div>
</template>

<script>
// import PageTitlebar from "@/components/UI/PageTitlebar";
import WidgetGrid from "@/components/UI/Widgets/WidgetGrid";
import AdvertiserReportingOverviewMonitor from "@/components/Advertiser/AdvertiserReportingOverviewMonitor";

export default {
  name: "AdvertiserOverview",
  components: {
    AdvertiserReportingOverviewMonitor,
    // PageTitlebar,
    WidgetGrid
  },
  data() {
    return {
      widgets: [
        {
          component: "AdvertiserAccountsOverviewWidget",
          props: {}
        },
        {
          component: "AdvertiserTopPerformersWidget",
          props: {}
        },
        {
          component: "AdvertiserInventorySourcesImportHistoryWidget",
          props: {}
        }
        /*{
          component: "AdvertiserVisitorsBreakdownWidget",
          props: {}
        }*/
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss"></style>
