<template>
  <div class="widget-grid" :key="'widgetGrid_' + groupKey">
    <div v-if="!draggable" class="row items-start q-col-gutter-lg">
      <component
        v-for="element in widgets"
        :key="groupKey + '_' + element.component"
        :is="element.component"
        v-bind="element.props"
      />
    </div>
    <div v-else>
      <draggable
        :group="groupKey"
        v-model="draggableWidgets"
        :item-key="
          element =>
            groupKey +
            '_' +
            element.component +
            '_' +
            Math.floor(Math.random() * 1000)
        "
        class="row items-start q-col-gutter-lg"
      >
        <template #item="{element}">
          <component
            :is="element.component"
            v-bind="element.props"
            :key="groupKey + '_' + element.component"
            class="--draggable"
          />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { defineAsyncComponent } from "vue";

export default {
  name: "WidgetGrid",
  props: {
    groupKey: {
      type: String,
      required: true
    },
    widgets: {
      type: Array,
      required: true
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // TODO: Auto-register all widget components, so widgets dont need to be added here?
    draggable,
    AdvertiserAccountsOverviewWidget: defineAsyncComponent(() =>
      import(
        "@/components/Advertiser/Widgets/AdvertiserAccountsOverviewWidget.vue"
      )
    ),
    AdvertiserTopPerformersWidget: defineAsyncComponent(() =>
      import(
        "@/components/Advertiser/Widgets/AdvertiserTopPerformersWidget.vue"
      )
    ),
    AdvertiserInventorySourcesImportHistoryWidget: defineAsyncComponent(() =>
      import(
        "@/components/Advertiser/Widgets/AdvertiserInventorySourcesImportHistoryWidget.vue"
      )
    ),
    AdvertiserVisitorsBreakdownWidget: defineAsyncComponent(() =>
      import(
        "@/components/Advertiser/Widgets/AdvertiserVisitorsBreakdownWidget.vue"
      )
    )
  },
  data() {
    return {
      draggableWidgets: this.widgets
    };
  },
  watch: {
    draggableWidgets(val) {
      localStorage.setItem(this.groupKey + "_widgets", JSON.stringify(val));
    }
  },
  mounted() {
    this.initWidgetsOrder();
  },
  methods: {
    initWidgetsOrder() {
      let localStorageWidgetsOrder = localStorage.getItem(
        this.groupKey + "_widgets"
      );

      if (localStorageWidgetsOrder) {
        localStorageWidgetsOrder = JSON.parse(localStorageWidgetsOrder);

        let localStorageWidgetNames = localStorageWidgetsOrder.map(
          localStorageWidget => localStorageWidget.component
        );

        let localWidgetNames = this.draggableWidgets.map(
          localWidget => localWidget.component
        );

        // Check widgets keys/structure, and on match re-order as needed.
        if (
          localStorageWidgetNames.length == localStorageWidgetsOrder.length &&
          localStorageWidgetNames.every(localStorageWidgetName =>
            localWidgetNames.includes(localStorageWidgetName)
          )
        ) {
          this.draggableWidgets.sort(
            (a, b) =>
              localStorageWidgetNames.indexOf(a.component) -
              localStorageWidgetNames.indexOf(b.component)
          );
        }
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
